<script lang="ts">
	import Glide from "@glidejs/glide";
	import type { ActionReturn } from "svelte/action";
	import { disableGlidePerSlideAmount } from "../utils/disableGlidePerSlideAmount";
	import StaticPicture from "./Picture/StaticPicture.svelte";
	import Icon from "./Icon.svelte";
	import arrowLeft from "../../../core/assets/icons/arrow-left.svg?raw";
	import arrowRight from "../../../core/assets/icons/arrow-right.svg?raw";
	import type { ImageSizes } from "../../../core/schema/Image";

	export let images: ImageSizes[];
	export let aspectSquare = false;

	const buttons: string[] = [arrowLeft, arrowRight];

	function glide(element: HTMLDivElement): ActionReturn {
		const glideOptions = {
			type: "carousel",
			startAt: 0,
			focusAt: "center",
			perView: 1,
			autoplay: 7000,
			hoverpause: false,
			animationDuration: 650,
			animationTimingFunc: "ease-in-out",
			gap: 9,
			keyboard: true,
			cloningRatio: 2,
		} satisfies Partial<Glide.Options>;

		const glide = new Glide(element, { ...glideOptions }).mount();
		glide.on("resize", () => {
			disableGlidePerSlideAmount(glide, element);
		});

		return {
			destroy(): void {
				glide.destroy();
			},
		};
	}
</script>

<div
	use:glide
	class="glide relative w-full max-w-[21rem] pb-12 min-[500px]:pb-0 md:max-w-[20rem] lg:max-w-[27rem] xl:max-w-[31rem]"
>
	<div class="glide__track overflow-hidden" data-glide-el="track">
		<div
			class="glide__slides flex {aspectSquare
				? 'h-[18rem] min-[360px]:h-[21rem] md:h-[20rem] lg:h-[27rem] xl:h-[31rem]'
				: 'h-[20rem] min-[360px]:h-[25rem] min-[400px]:h-[30rem] md:h-[35rem] xl:h-[39rem]'}"
		>
			{#each images as image}
				<StaticPicture
					class="glide__slide relative h-full w-full overflow-hidden rounded-lg"
					imgClass="w-full max-h-full rounded-lg object-cover m-auto inset-0 absolute {aspectSquare
						? 'aspect-square'
						: ''}"
					image={image.lg}
					width={496}
					height={aspectSquare ? 496 : 621}
					loading="lazy"
					alt="Fotografie"
					sourcesets={{
						"<md": image.sm,
						"<lg": image.md,
						">lg": image.lg,
					}}
				/>
			{/each}
		</div>
		<div class="absolute bottom-0 left-6 right-6 flex w-[calc(100%-3rem)] items-end gap-4 min-[500px]:bottom-6">
			<div class="hidden grow rounded-full bg-white px-3 py-[.7rem] min-[500px]:block">
				<div class="glide__bullets bg-secondaryBlack-200 flex h-[2px]" data-glide-el="controls[nav]">
					{#each images as _image, index}
						<span
							class="glide__bullet text-secondaryBlack hover:bg-secondaryBlack-300 w-full cursor-pointer transition-all duration-200"
							data-glide-dir="={index}"
						></span>
					{/each}
				</div>
			</div>
			<div class="flex w-full justify-center gap-4 min-[500px]:w-[initial] min-[500px]:gap-2" data-glide-el="controls">
				{#each buttons as button, index}
					<button
						aria-label={`Posuňte ${index === 0 ? "doleva" : "doprava"}`}
						data-glide-dir={index === 0 ? "<" : ">"}
						class="border-secondaryBlack hover:border-primaryRed group flex h-8 w-8 shrink-0 items-center justify-center rounded-full border p-4 transition-colors duration-300 min-[500px]:border-0 min-[500px]:bg-white xl:h-[3.5rem] xl:w-[3.5rem]"
					>
						<Icon
							icon={button}
							class="text-secondaryBlack group-hover:text-primaryRed h-4 transition-colors duration-300 xl:mt-[.1rem] xl:h-6 {index ===
							0
								? 'ml-[.25rem]'
								: 'mr-[.25rem]'}"
						/>
					</button>
				{/each}
			</div>
		</div>
	</div>
</div>
